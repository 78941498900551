<template>
  <div>
    <KTCodePreview v-bind:title="'Thông tin chương trình coupon'">
      <template v-slot:preview>
        <b-col>
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-form-group class="required-control">
                    <label>Tên chương trình</label>
                    <b-form-input
                      size="sm"
                      v-model="$v.mainModel.name.$model"
                      placeholder="Nhập tên"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <label>Mô tả</label>
                    <b-form-textarea
                      v-model="mainModel.description"
                      placeholder="Nhập mô tả"
                      rows="5"
                      :max-length="255"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" v-if="mainModel.applyChanel !== 3">
                  <b-form-group>
                    <label
                      >Cửa hàng áp dụng (để trống nếu áp dụng toàn hệ
                      thống)</label
                    >
                    <b-input-group>
                      <vue-multi-select
                        style="margin-bottom: 2rem"
                        v-model="mainModel.storeIds"
                        :options="stores"
                        placeholder="Chọn cửa hàng"
                        option-label="name"
                      ></vue-multi-select>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <b-form-group class="required-control">
                    <label>Từ ngày</label>
                    <date-picker
                      placeholder="Từ ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="mainModel.startDate"
                    ></date-picker>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="required-control">
                    <label>Đến ngày</label>
                    <date-picker
                      placeholder="Đến ngày"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="mainModel.endDate"
                    ></date-picker>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group label="Giá trị coupon">
                    <b-row>
                      <b-col md="6" class="pr-0">
                        <b-form-select
                          size="sm"
                          v-model="mainModel.type"
                          :options="listType"
                          value-field="id"
                          text-field="name"
                          @change="validateCouponValue()"
                        ></b-form-select>
                      </b-col>
                      <b-col md="6" class="pl-0">
                        <b-form-input
                          size="sm"
                          v-model="mainModel.value"
                          placeholder="Nhập giá trị coupon"
                          class="text-right"
                          v-mask="mask"
                          aria-describedby="input-discount-live-feedback"
                          @change="validateCouponValue()"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          id="input-discount-live-feedback"
                          :state="isValidCouponValue"
                          >{{ messageErrorDiscount }}</b-form-invalid-feedback
                        >
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Trạng thái">
                    <b-form-input
                      size="sm"
                      v-model="mainModel.status"
                      value-field="id"
                      text-field="name"
                      :disabled="true"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <label>Giá trị giảm giá tối đa</label>
                    <b-form-input
                      size="sm"
                      v-model="mainModel.maxValue"
                      placeholder="Nhập giá trị giảm giá tối đa"
                      class="text-right"
                      v-mask="mask"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <label>Áp dụng cho hóa đơn có tổng tiền từ</label>
                    <b-form-input
                      size="sm"
                      v-model="mainModel.applyFromAmount"
                      placeholder="Nhập giá trị hóa đơn tối thiểu áp dụng coupon"
                      class="text-right"
                      v-mask="mask"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <label>Kênh áp dụng</label>
                    <b-form-select
                      size="sm"
                      v-model="mainModel.applyChanel"
                      :options="listChanel"
                      value-field="id"
                      text-field="name"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-checkbox v-model="mainModel.applyWithPromotion"
                    >Áp dụng cùng chương trình chiết khấu</b-checkbox
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <h4>Mã coupon</h4>
          <div class="separator separator-dashed my-5"></div>
          <b-button
            size="sm"
            variant="primary"
            class="font-weight-bolder mb-4"
            @click="addCouponCode"
          >
            <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
          </b-button>
          <b-table
            class="table-bordered table-hover col-md-6"
            :fields="fieldDetail"
            :items="mainModel.details"
            v-if="renderComponent"
            :per-page="10"
            :current-page="currentPageDetail"
            id="my-table-detail"
          >
            <template v-slot:cell(code)="row">
              <b-input
                type="text"
                size="sm"
                class="input-style"
                v-model="row.item.code"
              ></b-input>
            </template>
            <template v-slot:cell(maxUse)="row">
              <b-input
                type="text"
                size="sm"
                class="input-style text-right"
                v-model="row.item.maxUse"
                v-mask="mask"
              ></b-input>
            </template>
            <template v-slot:cell(actions)="row">
              <v-icon
                small
                class="text-danger text-center"
                @click="deleteItemInArray(row.item.id)"
                v-b-tooltip
                title="Xóa"
                :disabled="row.item.used !== 0"
                >mdi-delete</v-icon
              >
            </template>
          </b-table>
          <b-pagination
            v-show="mainModel.details.length > 10"
            v-model="currentPageDetail"
            :total-rows="rowsDetail"
            :per-page="10"
            aria-controls="my-table-detail"
            align="right"
            class="col-md-6 pr-0"
          ></b-pagination>
          <h4>Sản phẩm/ danh mục</h4>
          <div class="separator separator-dashed my-5"></div>
          <b-row>
            <b-form-group class="col-md-12 mt-4 pr-0">
              <label for="input-code">Tìm sản phẩm/ danh mục:</label>
              <b-col md="12" class="row pr-0">
                <b-col md="6" class="p-0">
                  <vue-autosuggest
                    v-model="searchProductFor"
                    :suggestions="filteredOptionsProductFor"
                    @selected="onSelectedProductFor"
                    :limit="10"
                    @input="onInputChangeProductFor"
                    :input-props="{
                      class: 'autosuggest__input',
                      placeholder: 'Nhập tên sản phẩm',
                      style: 'border-radius:0px!important',
                    }"
                    :should-render-suggestions="
                      (size, loading) => size >= 0 && !loading
                    "
                  >
                    <template slot-scope="{ suggestion }">
                      <div>{{ suggestion.item.name }}</div>
                    </template>
                  </vue-autosuggest>
                </b-col>
                <b-col md="2" class="p-0">
                  <b-form-select
                    v-model="selectTypeSearch"
                    :options="listTypeSearch"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    @change="debounceInputFor"
                  ></b-form-select>
                </b-col>
                <b-col md="2" class="p-0">
                  <b-form-select
                    v-model="selectTypeOfProduct"
                    :options="listTypeOfProduct"
                    size="sm"
                    value-field="id"
                    text-field="name"
                    @change="debounceInputFor"
                  ></b-form-select>
                </b-col>
                <b-col md="2" class="pr-0">
                  <div class="d-flex justify-content-end">
                    <b-button
                      size="sm"
                      variant="secondary"
                      class="font-weight-bolder"
                      @click="showModalImportProduct"
                    >
                      <i style="font-size: 1rem" class="far fa-file-excel"></i
                      >Nhập SP từ excel
                    </b-button>
                  </div>
                </b-col>
              </b-col>
            </b-form-group>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <div>
                <b-table
                  class="col-md-8"
                  bordered
                  hover
                  :fields="fieldProduct"
                  :items="mainModel.listProduct"
                  v-if="renderComponent"
                  :per-page="10"
                  :current-page="currentPageTable"
                  id="my-table-product"
                >
                  <template v-slot:cell(type)="row">
                    <span v-if="row.item.type === 1">Sản phẩm</span>
                    <span v-if="row.item.type === 2">Nhóm sản phẩm</span>
                  </template>
                  <template v-slot:cell(actions)="row">
                    <v-icon
                      small
                      class="text-danger text-center"
                      @click="deleteItemProductInArray(row.item.id)"
                      v-b-tooltip
                      title="Xóa"
                      >mdi-delete</v-icon
                    >
                  </template>
                </b-table>
                <b-pagination
                  v-show="mainModel.listProduct.length > 10"
                  v-model="currentPageTable"
                  :total-rows="rowsTable"
                  :per-page="10"
                  aria-controls="my-table-product"
                  align="right"
                  class="col-md-8 pr-0"
                ></b-pagination>
              </div>
            </b-col>
            <b-col cols="6" md="4"></b-col>
          </b-row>
        </b-col>
        <b-modal
          ref="import-product-modal"
          hide-footer
          title="Thêm sản phẩm từ Excel"
          id="modal-prevent-closing"
          size="lg"
        >
          <b-row>
            <b-col>
              <div class="form-group">
                <span>
                  <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                  đúng format, hoặc có thể tải ở
                  <a :href="urlExcel">đây</a>
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <span> <b>Bước 2:</b> Tiến hành Import </span>
              <div style="padding-top: 15px">
                <b-form-file
                  placeholder="Hãy chọn một tập tin"
                  @change="selectFile"
                  v-model="file"
                  ref="file-input"
                  class="mb-2"
                ></b-form-file>
              </div>
            </b-col>
          </b-row>
          <b-container class="mt-4 pl-0 pr-0" v-show="importItems.length > 0">
            <h5>
              Danh sách sản phẩm từ file excel( Tổng:
              {{ importItems.length }} sản phẩm)
            </h5>
            <b-table
              id="my-table"
              class="myTable"
              responsive
              bordered
              hover
              :fields="fieldImport"
              :items="importItems"
              :per-page="10"
              :current-page="currentPage"
            >
              <template v-slot:cell(type)="row">
                <span v-if="row.item.type === 1">Sản phẩm</span>
                <span v-if="row.item.type === 2">Nhóm sản phẩm</span>
              </template>
            </b-table>
            <b-pagination
              v-show="importItems.length > 10"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="10"
              aria-controls="my-table"
            ></b-pagination>
          </b-container>
          <b-button
            style="fontweight: 600; width: 70px"
            variant="primary"
            size="sm"
            @click="validData"
            >Lưu</b-button
          >
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
            @click="hideModalImportProduct"
            >Hủy</b-button
          >
        </b-modal>
      </template>
      <template v-slot:foot>
        <!-- <b-col lg="4" class="pb-2"> -->
        <b-container class="bv-example-row">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              :disabled="isEnableUpdate"
              v-if="checkPermission('COUPON_UPDATE')"
              >Lưu</b-button
            >
            <b-button
              :style="
                checkPermission('COUPON_UPDATE')
                  ? 'margin-left: 10px; font-weight: 600; width: 70px'
                  : 'font-weight: 600; width: 70px'
              "
              variant="secondary"
              size="sm"
              @click="onBack"
              >Hủy</b-button
            >
          </b-row>
        </b-container>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}

.center-label label {
  margin-bottom: 0px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
// import { removeAccents } from './../../../utils/common';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { getToastInstance } from './../../../utils/toastHelper';
import _ from 'lodash';
import { currencyMask, unMaskPrice } from './../../../utils/common';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import VueMultiSelect from 'vue-simple-multi-select';
import decounce from 'debounce';
import {
  TIME_TRIGGER,
  URL_IMPORT_PRODUCT_COUPON,
} from './../../../utils/constants';
import xlsx from 'xlsx';
import { v4 } from 'uuid';
import localData from '../../../utils/saveDataToLocal';
import { AMOUNT_TYPE } from './../../../utils/enum';

export default {
  mixins: [validationMixin],
  data() {
    return {
      renderComponent: true,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      mask: currencyMask,
      originProgramStatus: null,
      mainModel: {
        id: 0,
        name: null,
        description: '',
        startDate: new Date(),
        endDate: null,
        details: [],
        storeIds: [],
        status: true,
        type: 1,
        value: 0,
        maxValue: 0,
        applyFromAmount: 0,
        applyWithPromotion: false,
        listProduct: [],
        applyChanel: 1,
      },
      listProductFor: [],
      filteredOptionsProductFor: [],
      optionsProductFor: [
        {
          data: [],
        },
      ],
      listProduct: [],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProductAccessory: '',
      accessoryItems: [],
      searchProductFor: '',
      categories: '',
      stores: '',
      listStatus: [
        {
          id: true,
          name: 'Hoạt động',
        },
        {
          id: false,
          name: 'Không hoạt động',
        },
      ],
      listType: [
        {
          id: 1,
          name: 'Tiền mặt $',
        },
        {
          id: 2,
          name: 'Chiết khấu %',
        },
      ],
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
        {
          id: 3,
          name: 'Nhóm sản phẩm',
        },
      ],
      selectTypeSearch: 1,
      listTypeOfProduct: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeOfProduct: 0,
      isSearching: false,
      importItems: [],
      file: null,
      excellist: [],
      fieldImport: [
        {
          key: 'type',
          label: 'Loại',
        },
        {
          key: 'code',
          label: 'Mã',
        },
        {
          key: 'name',
          label: 'Tên',
        },
      ],
      currentPage: 1,
      urlExcel: URL_IMPORT_PRODUCT_COUPON,
      currentPageTable: 1,
      currentPageDetail: 1,
      fieldDetail: [
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '45%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'maxUse',
          label: 'Số lần sử dụng',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'used',
          label: 'Đã sử dụng',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '25%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      fieldProduct: [
        {
          key: 'type',
          label: 'Loại',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '20%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'code',
          label: 'Mã',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'name',
          label: 'Tên',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32' },
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            textAlign: 'center',
            width: '5%',
          },
        },
      ],
      couponProgramId: null,
      listChanel: [
        {
          id: 1,
          name: 'Offline & Online',
        },
        {
          id: 2,
          name: 'Offline',
        },
        {
          id: 3,
          name: 'Online',
        },
      ],
      messageErrorDiscount: '',
      isValidCouponValue: true,
    };
  },
  validations: {
    mainModel: {
      startDate: {
        required,
        minValue: minValue(0),
      },
      name: {
        required,
      },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    VueMultiSelect,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Coupon', route: '/coupon' },
      {
        title: 'Cập nhật chương trình coupon',
      },
    ]);
    this.couponProgramId = this.$route.query.id;
    this.getCouponProgramById();
  },
  computed: {
    rows() {
      return this.importItems.length;
    },
    rowsTable() {
      return this.mainModel.listProduct.length;
    },
    rowsDetail() {
      return this.mainModel.details.length;
    },
    isEnableUpdate() {
      return !this.originProgramStatus;
    },
    isDuringEvent() {
      return (endDate) => {
        const currentDate = moment().format();
        return moment(currentDate).isBefore(endDate);
      };
    },
    convertToISODate() {
      return (date, currentFormat, toFormat = null) => {
        return moment(date, currentFormat).format(toFormat);
      };
    },
  },
  created() {
    this.fetchStore();
  },
  methods: {
    ...getToastInstance(),
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    fetchStore: async function () {
      this.stores = [];
      ApiService.get(`stores?limit=1000&page=1`).then((response) => {
        response.data.data.list_store.map((element) => {
          let store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.stores.push(store);
        });
      });
    },
    formatMoney(number) {
      const money = new Intl.NumberFormat('vn-VN').format(number);
      return _.isNaN(money) ? 0 : money;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          this.mainModel.details = _.reject(
            this.mainModel.details,
            (product) => {
              return product.productId == item.productId;
            },
          );
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.mainModel.details.length; i++) {
        if (this.mainModel.details[i].id === id) {
          this.mainModel.details.splice(i, 1);
        }
      }
    },
    fetchProductFor(textSearch) {
      this.isSearching = true;
      this.listProduct = [];
      this.optionsProductFor[0].data = [];
      ApiService.get(
        `productSearch/search-by-product-category?searchProduct=${textSearch}&typeSearch=${this.selectTypeSearch}&typeOfProduct=${this.selectTypeOfProduct}`,
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          this.optionsProductFor[0].data.push(element);
        });
        this.filteredOptionsProductFor = [
          {
            data: this.optionsProductFor[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProductFor) {
          let nextTextSearch = this.searchProductFor;
          this.fetchProductFor(nextTextSearch);
        }
      });
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      let type = 1;
      if (this.selectTypeSearch === 3) {
        type = 2;
      }
      const item = {
        id: this.newId(),
        type: type,
        referenceId: option.item.id,
        name: option.item.name,
        code: option.item.code,
      };
      this.mainModel.listProduct.push(item);
    },
    onInputChangeProductFor(text) {
      this.searchProductFor = text;

      this.debounceInputFor();
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    onBack: function () {
      history.back();
    },
    checkValidDataDetail() {
      let check = false;
      if (this.mainModel.details.length === 0) {
        this.makeToastFaile('Vui lòng nhập mã coupon!');
        return;
      }
      for (let index = 0; index < this.mainModel.details.length; index++) {
        const element = this.mainModel.details[index];
        const item = this.mainModel.details.find(
          (item) => item.code === element.code && item.id !== element.id,
        );
        if (item) {
          this.makeToastFaile(
            `Mã ${element.code} bị trùng trong danh sách mã coupon!`,
          );
          return;
        }
        const itemNull = this.mainModel.details.find(
          (item) => item.code.trim() === '' || item.code.trim().length < 5,
        );
        if (itemNull) {
          this.makeToastFaile(
            `Vui lòng nhập mã coupon hợp lệ (ít nhất 5 ký tự)!`,
          );
          return;
        }
      }
      check = true;
      return check;
    },
    onSubmit: function () {
      if (!this.mainModel.startDate) {
        this.makeToastFaile('Vui lòng nhập ngày bắt đầu chương trình coupon!');
        return;
      }
      if (!this.mainModel.endDate) {
        this.makeToastFaile('Vui lòng nhập ngày kết thúc chương trình coupon!');
        return;
      }
      this.validateCouponValue();
      if (!this.isValidCouponValue) {
        this.makeToastFaile('Vui lòng nhập giá trị coupon hợp lệ!');
        return;
      }
      if (!this.checkValidDataDetail()) {
        return;
      }
      let storeIds = '-1';
      if (this.mainModel.storeIds.length > 0) {
        storeIds = '';
        this.mainModel.storeIds.forEach((element, index) => {
          if (index === this.mainModel.storeIds.length - 1) {
            storeIds += element.id;
          } else {
            storeIds += element.id + ', ';
          }
        });
      }
      this.mainModel.details.forEach((element) => {
        element.maxUse = unMaskPrice(element.maxUse);
      });
      const startDate = this.convertToISODate(
        this.mainModel.startDate,
        'DD/MM/YYYY',
        'YYYY-MM-DDT00:00:00.000',
      );
      const endDate = this.convertToISODate(
        this.mainModel.endDate,
        'DD/MM/YYYY',
        'YYYY-MM-DDT23:59:59.000',
      );
      if (moment(startDate).isSameOrAfter(endDate)) {
        this.makeToastFailure(
          'Ngày bắt đầu coupon không được lớn hơn ngày kết thúc',
        );
        return;
      }
      const data = {
        id: this.couponProgramId,
        name: this.$v.mainModel.name.$model,
        description: this.mainModel.description,
        status: this.isDuringEvent(endDate),
        listDetail: this.mainModel.details,
        listProduct: this.mainModel.listProduct,
        startDate: this.mainModel.startDate
          ? moment(this.mainModel.startDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 00:00:00',
            )
          : null,
        endDate: this.mainModel.endDate
          ? moment(this.mainModel.endDate, 'DD/MM/YYYY').format(
              'yyyy-MM-DD 23:59:59',
            )
          : null,
        type: this.mainModel.type,
        value: this.mainModel.value ? unMaskPrice(this.mainModel.value) : 0,
        maxValue: this.mainModel.maxValue
          ? unMaskPrice(this.mainModel.maxValue)
          : 0,
        storeIds: storeIds,
        applyFromAmount: this.mainModel.applyFromAmount
          ? unMaskPrice(this.mainModel.applyFromAmount)
          : 0,
        applyWithPromotion: this.mainModel.applyWithPromotion,
        applyChanel: this.mainModel.applyChanel,
      };
      ApiService.put('coupon', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            setTimeout(() => {
              history.back();
            }, 500);
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
        });
    },
    debounceInputFor: decounce(function () {
      let textSearch = this.searchProductFor;
      if (!this.isSearching) {
        this.fetchProductFor(textSearch);
      }
    }, TIME_TRIGGER),
    selectFile(event) {
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx',
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              type: element['Loại'] === 'Sản phẩm' ? 1 : 2,
              name: element['Tên'],
              code: element['Mã'],
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModalImportProduct() {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData() {
      const data = {
        listProduct: this.importItems,
      };
      ApiService.post('coupon/valid-import-product', data)
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.makeToastSuccess(message);
            response.data.data.forEach((element) => {
              this.mainModel.listProduct.push(element);
            });
            this.hideModalImportProduct();
          } else {
            this.makeToastFailure(message);
          }
        })
        .catch((err) => {
          const message = err;
          this.makeToastFailure(message);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFailure(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    newId() {
      return v4();
    },
    addCouponCode() {
      if (this.mainModel.details.length > 0) {
        for (let index = 0; index < this.mainModel.details.length; index++) {
          const element = this.mainModel.details[index];
          if (element.code.trim() === '' || element.code.length < 5) {
            this.makeToastFaile('Vui lòng nhập mã coupon với ít nhất 5 ký tự!');
            return;
          } else {
            const item = this.mainModel.details.find(
              (item) => item.code === element.code && item.id !== element.id,
            );
            if (item) {
              this.makeToastFaile(`Mã coupon ${element.code} đã tồn tại!`);
              return;
            }
          }
        }
        this.mainModel.details.push({
          id: this.newId(),
          code: '',
          maxUse: 1,
          used: 0,
        });
      } else {
        this.mainModel.details.push({
          id: this.newId(),
          code: '',
          maxUse: 1,
          used: 0,
        });
      }
    },
    deleteItemProductInArray(id) {
      for (var i = 0; i < this.mainModel.listProduct.length; i++) {
        if (this.mainModel.listProduct[i].id === id) {
          this.mainModel.listProduct.splice(i, 1);
        }
      }
    },
    getCouponProgramById: async function () {
      await ApiService.get('coupon/' + this.couponProgramId).then((res) => {
        const {
          name,
          description,
          startDate,
          endDate,
          // status,
          listDetail,
          listProduct,
          type,
          value,
          maxValue,
          applyFromAmount,
          applyWithPromotion,
          applyChanel,
        } = res.data.data;
        const currentDate = moment().format();
        let newStatus = moment(currentDate).isBefore(endDate);
        this.$v.mainModel.name.$model = name;
        this.mainModel.description = description;
        this.mainModel.startDate =
          startDate !== null ? moment(startDate).format('DD/MM/YYYY') : '';
        this.mainModel.endDate =
          endDate !== null ? moment(endDate).format('DD/MM/YYYY') : '';
        this.mainModel.status = newStatus ? 'Hoạt động' : 'Không hoạt động';
        this.mainModel.details = listDetail;
        this.mainModel.storeIds = res.data.data.storeIds;
        this.mainModel.listProduct = listProduct;
        this.mainModel.applyFromAmount = applyFromAmount;
        this.mainModel.applyWithPromotion = applyWithPromotion;
        this.mainModel.value = value;
        this.mainModel.type = type;
        this.mainModel.maxValue = maxValue;
        this.mainModel.applyChanel = applyChanel;

        this.originProgramStatus = newStatus;
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    validateCouponValue() {
      this.isValidCouponValue = true;
      if (
        this.mainModel.type === AMOUNT_TYPE.MONEY &&
        unMaskPrice(this.mainModel.value) > 10000000
      ) {
        this.messageErrorDiscount = 'Giá trị coupon vượt quá 10 triệu VND!';
        this.isValidCouponValue = false;
      }
      if (
        this.mainModel.type === AMOUNT_TYPE.PERCENT &&
        unMaskPrice(this.mainModel.value) > 100
      ) {
        this.messageErrorDiscount = 'Giá trị coupon vượt quá 100%!';
        this.isValidCouponValue = false;
      }
    },
  },
};
</script>

<style>
.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 500;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>
